.container {
  width: 100%;
  max-width: 100vw;
  padding-right: var(--bs-gutter-x, 0.25rem);
  padding-left: var(--bs-gutter-x, 0.25rem);
  margin-right: auto;
  margin-left: auto;
}
.grecaptcha-badge{
  visibility: hidden;
}
@media (max-width: 600px) {
  .container {
    /* max-width: 540px; */
    max-width: 93vw;
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 600px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 900px) {
  .container {
    /* max-width: 960px; */
    max-width: 720px;
  }
}

@media (min-width: 1200px) {
  .container {
    /* max-width: 1140px; */
    max-width: 900px;
    /* max-width: 1000px; */
  }
}

@media (min-width: 1400px) {
  .container {
    /* max-width: 1320px; */
    /* max-width: 1080px; */
    max-width: 1100px;
  }
}

@media (min-width: 1550px) {
  .container {
    /* max-width: 1320px; */
    /* max-width: 1080px; */
    max-width: 1300px;
  }
}

.font-fa {
  font-family: fa;
}
.font-en {
  font-family: en;
}

.slider-item {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently*/

  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

* {
  scrollbar-color: #f5f5f5 #555;
  scrollbar-width: thin;
}

::-webkit-scrollbar-track {
  background-color: #555;
  border-radius: 20px;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: #555;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #34384c;
  border-radius: 20px;
}

.red-blink-dot::before {
  animation: blink 2s infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes card-pack-scale {
  35% {
    transform: scale(1.05);
  }
}

@keyframes card-pack-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes card-pack-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes card-pack-mask {
  100% {
    -webkit-mask-position-x: -5580px;
  }
}

.Toastify__toast {
  font-family: inherit !important;
}

.react-multi-carousel-list {
  direction: ltr;
}
