@font-face {
  font-family: "en";
  src: url("../fonts/en/BinancePlex-Medium.woff2") format("woff2");
  src: url("../fonts/en/BinancePlex-Medium.woff") format("woff"),
    url("../fonts/en/BinancePlex-Medium.eot"),
    url("../fonts/en/BinancePlex-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/en/BinancePlex-Medium.ttf") format("truetype"),
    url("../fonts/en/BinancePlex-Medium.svg#BinancePlex-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "en";
  src: url("../fonts/en/BinancePlexRegular.woff2") format("woff2");
  src: url("../fonts/en/BinancePlexRegular.woff") format("woff"),
    url("../fonts/en/BinancePlexRegular.eot"),
    url("../fonts/en/BinancePlexRegular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/en/BinancePlexRegular.ttf") format("truetype"),
    url("../fonts/en/BinancePlexRegular.svg#BinancePlexRegular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "en";
  src: url("../fonts/en/BinancePlex-SemiBold.woff2") format("woff2");
  src: url("../fonts/en/BinancePlex-SemiBold.woff") format("woff"),
    url("../fonts/en/BinancePlex-SemiBold.eot"),
    url("../fonts/en/BinancePlex-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/en/BinancePlex-SemiBold.ttf") format("truetype"),
    url("../fonts/en/BinancePlex-SemiBold.svg#BinancePlex-SemiBold")
      format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "en";
  src: url("../fonts/en/BinancePlex-SemiBold.woff2") format("woff2");
  src: url("../fonts/en/BinancePlex-SemiBold.woff") format("woff"),
    url("../fonts/en/BinancePlex-SemiBold.eot"),
    url("../fonts/en/BinancePlex-SemiBold.eot?#iefix")
      format("embedded-opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "en";
  src: url("../fonts/en/BinancePlex-SemiBold.woff2") format("woff2");
  src: url("../fonts/en/BinancePlex-SemiBold.woff") format("woff"),
    url("../fonts/en/BinancePlex-SemiBold.eot"),
    url("../fonts/en/BinancePlex-SemiBold.eot?#iefix")
      format("embedded-opentype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
