@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
