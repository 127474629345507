.phone-input-container {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em !important;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 4px;
}

.phone-input-container .phone-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  /* padding: 4px 0 5px; */
  border: 0;
  box-sizing: content-box;
  background: none !important;
  height: 1.4375em !important;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
  padding-left: 65px !important;
  
}
.light .phone-input{
border: 1px solid rgba(0, 0, 0, 0.23);
}
.dark .phone-input{
  border: 1px solid rgba(255, 255, 255, 0.23);
}
.phone-input-container .dark .phone-input:hover {
  border-color: #fff;
}
.phone-input-container .light .phone-input:hover {
  border-color: #000;
}
.phone-input-container .phone-input:focus {
  border-width: 2px;
  border-color: rgb(171,15,9) !important;
  box-shadow: none !important;
}
.phone-input-container .phone-input:hover + .phone-input-button {
  border-color: #fff !important;
}
.phone-input-container .phone-input:focus + .phone-input-button {
  
  border-color: rgb(220,72,66) !important;
}
.phone-input-container.dark .special-label {
  background-color: #121212 !important;
}

/* .phone-input-container .phone-input-button {
  background: none !important;
  border: none;
  border-right: 2px solid rgba(255, 255, 255, 0.23);
} */
.phone-input-container .phone-input-button .selected-flag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 50px;
}

.phone-input-container .phone-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px rgb(0 0 0 / 90%) inset;
  box-shadow: 0 0 0 1000px rgb(0 0 0 / 90%) inset;
  -webkit-text-fill-color: #fff !important;
}

.phone-input-container.dark .phone-input-dropdown {
  background-color: #292a30 !important;
  width: 381px !important;
}
.phone-input-container.dark .phone-input-dropdown .highlight {
  background-color: rgb(14, 13, 13) !important;
}
.phone-input-container.dark .phone-input-dropdown .country:hover {
  background-color: #121212 !important;
}

.phone-input-container .phone-input-search {
  width: 100%;
  background-color: #000 !important;
}

.phone-input-container .phone-input-search {
  display: flex;
  align-items: center;
  justify-content: center;
}
.phone-input-container .phone-input-search input {
  flex-grow: 1;
  background: none;
  border: none !important;
  color: #fff;
  padding: 7px 9px;
  margin: 0 !important;
  font-size: 17px !important;
}
