@font-face {
  font-family: "fa";
  src: url("../fonts/fa/IRANYekanWebFn.eot");
  src: url("../fonts/fa/IRANYekanWebFn.woff2") format("woff2"),
    url("../fonts/fa/IRANYekanWebFn.woff") format("woff"),
    url("../fonts/fa/IRANYekanWebFn.eot?#iefix") format("embedded-opentype"),
    url("../fonts/fa/IRANYekanWebFn.ttf") format("truetype"),
    url("../fonts/fa/IRANYekanWebFn.svg#IRANYekanWebFn") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "fa";
  src: url("../fonts/fa/IRANYekanWebFn-Bold.eot");
  src: url("../fonts/fa/IRANYekanWebFn-Bold.woff2") format("woff2"),
    url("../fonts/fa/IRANYekanWebFn-Bold.woff") format("woff"),
    url("../fonts/fa/IRANYekanWebFn-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/fa/IRANYekanWebFn-Bold.ttf") format("truetype"),
    url("../fonts/fa/IRANYekanWebFn-Bold.svg#IRANYekanWebFn-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
